<template>
    <v-container fluid>
        <v-row>
            {{this.item}}
        </v-row>
        <v-row>
            <v-btn type="button" height="3rem" class="rounded-l border-12 mr-12 pl-12 pr-12 pt-2 pb-2" color="primary" v-on:click="emit">Klik</v-btn>
        </v-row>
    </v-container>
</template>
<script>
export default {
    props: ['item'],
    data() {
        return { 
            data: null
        }
    },
    mounted(){
    },
    methods:{
        emit: function() {
			this.$emit('event_child', 5)
		},
        
    },
    watch: {
    }
}
</script>